import moment from 'moment-timezone';
import { Performance } from './datamodels';

const getRecordingDeadlineInMs = (performance: Performance) => {
    const {
        RecordingSessionStartTime,
        VideoRecordingMaximumSessionLength,
    } = performance;
    let recordingSessionStartTime = new Date(RecordingSessionStartTime).getTime();
    let sessionEndsDeadline = recordingSessionStartTime + (VideoRecordingMaximumSessionLength * 1000);
    return moment.tz(moment(new Date(sessionEndsDeadline)).format("YYYY-MM-DD HH:mm:SS"), "America/Los_Angeles").clone().tz(moment.tz.guess()).unix();
}

const getTzInMs = (date: Date) => {
    return moment.tz(moment(new Date(date)).format("YYYY-MM-DD HH:mm:SS"), "America/Los_Angeles").clone().tz(moment.tz.guess()).unix();
}

export default {
    getRecordingDeadlineInMs,
    getTzInMs,
};
