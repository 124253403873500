import React from 'react';
import {Card, CardHeader, Grid, Paper} from "@material-ui/core";
import VideoUploader from '../components/VideoUploader';
import { useSelector } from 'react-redux';


const maxFileSize = 5000000000

let signedURL = ''
const s3Path = ''

const UploadVideo = () => {
  const performance = useSelector((state: any) => state.performances.performance)
  return (
    <Card className="Card">
      <CardHeader title="Upload Video" className="Card-Header"/>
      <Paper>
        <Grid container justify="center">
          <VideoUploader perfomanceId={performance.id} />
        </Grid>
      </Paper>
    </Card>
  )
}

export default UploadVideo
