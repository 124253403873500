import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {AppBar, Button, Grid} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from '../redux/actions/RouteActions'
import {useStyles} from "../App";

interface HeaderProps {
  username?: string;
}

const isDevelopmentMode = () => {
  return process.env.NODE_ENV === 'development';
};

function Header(props: HeaderProps) {
  const classes = useStyles();

  const loggedIn = useSelector((state: any) => state.auth.loggedIn)
  const dispatch = useDispatch()
  const page = useSelector((state: any) => state.route.page)

  const username = props.username ? props.username : '';

  return (
    <AppBar color="transparent" position="sticky" elevation={0} className={classes.root}>
      <Grid container>
        <Grid item container md={2} justify="center">
          <img src="logo.png" className={classes.headerImage}/>
        </Grid>
        <Grid item container className={classes.menu} md={10}>
          <Grid item xs>
            <Button onClick={e => dispatch(setPage('current'))}
                    className={ page==='current' && loggedIn ? classes.selectedButton : classes.button}
                    disabled={!loggedIn}>
              Current Performances
            </Button>
          </Grid>
          <Grid item xs>
            <Button  onClick={e => dispatch(setPage('past'))}
                     className={ page==='past' && loggedIn ? classes.selectedButton : classes.button}
                     disabled={!loggedIn}>
              Past Performances
            </Button>
          </Grid>
          <Grid item xs>
            <Button  onClick={e => { dispatch(setPage('account'))}}
                     className={ page==='account' && loggedIn ? classes.selectedButton : classes.button}
                     disabled={!loggedIn}>
              Account Information
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default Header;
