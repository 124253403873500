import React from "react";
import { Grid, Typography } from "@material-ui/core";


const HeaderRow = (classes: any, past: boolean = false) => {
  return (
    <Grid item md={12} key={-1} className={classes.performance}>
      <Grid container>
        <Grid item md={3}>
          <Typography variant="h6">Event</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="h6">Team / Program</Typography>
        </Grid>
        <Grid item md={5}>
          <Typography variant="h6">Divison Name / Submission Info</Typography>
        </Grid>
        <Grid>
          <Typography variant="h6"></Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HeaderRow
