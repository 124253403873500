import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import {Redirect} from 'react-router-dom';

import './video-uploader.scss';
import 'react-dropzone-uploader/dist/styles.css';

import { submitVideo, beginVideoRecording, verifyVideo, getVideoMetaData } from '../redux/actions/PerformancesActions'
import {connect} from "react-redux";
import {Button, Typography} from "@material-ui/core";

class VideoUploader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      videoUrl: '',
      thanks: false
    };

    this.videoId = 0
    this.uploadUrl = ''

    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.getUploadParams = this.getUploadParams.bind(this);
    this.NoInputLayout = this.NoInputLayout.bind(this);
  }

  async getUploadParams({file, meta}) {
    const {name, type} = meta;
    const fileExtension = name.split('.').pop();

    this.videoId = parseInt(await this.props.beginVideoRecording(this.props.perfomanceId), 10)
    this.uploadUrl = await this.props.getS3URL(this.videoId, this.props.performanceId, fileExtension, type);
    return {method: 'PUT', body: file, url: this.uploadUrl.signedUrl, headers: { "Content-Type": type },
    };
  }

  handleChangeStatus = async ({ meta, file }, status) => {
    if(status === 'done') {
      const verifyResults = await verifyVideo(this.props.perfomanceId, this.videoId)
      const results = await getVideoMetaData(verifyResults.s3_key)
      this.setState({thanks: true})
    }
  }

  NoInputLayout({previews, dropzoneProps, input}) {
    return (
      <div {...dropzoneProps}>
        <div className="zone">
          <h1 className="zone__title">Drag and drop a Video</h1>
          <span className="zone__formats">We support video formats WEBM, MPEG, MP4, AVI, WMV, MOV, FLV</span>
        </div>
        {input}
        {previews}
      </div>
    );
  }

  render() {
    return (
      <div>
        { !this.state.thanks &&
        <Dropzone
          getUploadParams={this.getUploadParams}
          inputContent="Select a File"
          accept="video/*"
          maxFiles={1}
          multiple={false}
          onChangeStatus={this.handleChangeStatus}
          canCancel={false}
          LayoutComponent={this.NoInputLayout}
        /> }
        { this.state.thanks && <Typography variant="h2">Congratulations, your video has been uploaded</Typography> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({
  getS3URL: (videoId, performanceId, extension, mimeType) => dispatch(submitVideo(videoId, performanceId, extension, mimeType)),
  beginVideoRecording: (performanceId) => dispatch(beginVideoRecording(performanceId)),
  verifyVideo: (performanceId, videoId) => dispatch(verifyVideo(performanceId, videoId)),
  getVideoMetaData: (s3Key) => dispatch(getVideoMetaData(s3Key))
})

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(VideoUploader);
