import React, { useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getPerformances} from '../redux/actions/PerformancesActions'
import { Card, CardHeader, Grid, Paper, Typography } from "@material-ui/core";
import {Performance} from "../lib/datamodels"
import PerformanceRow from "../components/PerformanceRow";
import {makeStyles} from "@material-ui/core/styles";
import HeaderRow from "../components/HeaderRow";
import {useStyles} from "../App";
import LinearProgress from '@material-ui/core/LinearProgress';

const Past = () => {

  const styles = useStyles()
  const dispatch = useDispatch()

  useEffect(()=> {
    dispatch(getPerformances())
  }, [])

  let performances = useSelector((state: any) => state.performances.performances)
  const performancesLoading = useSelector((state: any) => state.performances.loading)

  const pastPerformances = useMemo(()=>{
    if (performances && performances.length) {
      return performances.filter((performance: Performance) => performance.SubmittedVideoClip !== 0)
    } else {
      return []
    }
  }, [performances])

  return (
    <Card className="Card">
      <CardHeader title="Past Performances" className="Card-Header"/>
      <Paper>
        <Grid container>
          <HeaderRow styles past />
          {pastPerformances.map((performance: Performance) => PerformanceRow(performance, 'Download Video', dispatch, styles, true))}
        </Grid>
        {performancesLoading ? 
        <>
          <LinearProgress/>
          <Typography>Finding Performances</Typography>
        </> : <>
          {pastPerformances.length === 0 ?
          <Typography>No Performances Found</Typography>
          : <Typography>Displaying {pastPerformances.length} performance(s)</Typography>
          }
        </>}
      </Paper>
    </Card>
  )
}

export default Past

