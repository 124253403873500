import { SET_USER, SET_LOGGED_IN } from "../actionTypes";

const initialState = {
  user: null,
  loggedIn: false,
  token: '',
  refreshToken: ''
};

interface UserAction {
    type: string;
    payload: any;
}

export default function(state = initialState, action: UserAction) {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
      };
    }
    case SET_LOGGED_IN: {
      return {
        ...state,
        loggedIn: action.payload
      }
    }
    default:
      return state;
  }
}
