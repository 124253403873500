import React, {useEffect, useState} from 'react';
import { Container } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

import Header from "./Header";
import Login from "../routes/Login";
import Current from "../routes/Current"
import Past from "../routes/Past"
import Account from "../routes/Account"
import PerformanceDetails from "../routes/PerformanceDetails"

import { setLoggedIn } from "../redux/actions/UserActions"

import {withRouter, RouteComponentProps} from "react-router";
import UploadVideo from "../routes/UploadVideo";
import {ACCESS_TOKEN} from "../lib/constants";
import { Grid } from "@material-ui/core";

function MainPage(props: RouteComponentProps) {
  const dispatch = useDispatch()
  const regex = /[?&]([^=#]+)=([^&#]*)/g
  const queryParams: any = {};
  let match;
  while(match = regex.exec(props.location.search)) {
    queryParams[match[1]] = match[2];
  }
  if (queryParams.jwt) {
    try {
      const decodedJWT = jwtDecode(queryParams.jwt)
      localStorage.setItem(ACCESS_TOKEN, queryParams.jwt)
      dispatch(setLoggedIn(true))
    } catch (e) {
      // Do nothing, just ignore the bad token
    }
  }

  useEffect(()=> {
    const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
    if (accessToken.length > 5) {
      dispatch(setLoggedIn(true))
    }
  }, [])

  const loggedIn = useSelector((state: any) => state.auth.loggedIn)
  const page = useSelector((state: any) => state.route.page)

  return (
    <Container>
      <div className="App">
        <Header/>
        <div className="App-body">
        <Grid container>
          {!loggedIn && (<Login/>)}
          {loggedIn && page === 'current' && <Current/>}
          {loggedIn && page === 'past' && <Past/>}
          {loggedIn && page === 'account' && <Account/>}
          {loggedIn && page === 'upload' && <UploadVideo/>}
          {loggedIn && page === 'performance' && <PerformanceDetails/>}
          </Grid>
        </div>
      </div>
    </Container>
  )
}

export default withRouter(MainPage)

