import React, { useEffect } from 'react';
import {Button} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {setDownloadVideo, getVideoClip, setPerformance} from '../redux/actions/PerformancesActions'
import {setPage} from "../redux/actions/RouteActions";

import { Performance } from "../lib/datamodels";
import {useStyles} from "../App";


interface DownloadButtonProps {
  performance: Performance;
}

const handleClick = async (performance: Performance, dispatch: any) => {
  if (performance.VideoClipId) {
    await dispatch(getVideoClip(performance.VideoClipId))
  } else {
    await dispatch(getVideoClip(null))
  }
  await dispatch(setPerformance(performance))
  await dispatch(setPage('performance'))
}

const handleDownloadComplete = (dispatch: any) => {
  dispatch(setDownloadVideo(['', '0']))
}

function DownloadButton(props: DownloadButtonProps) {
  const classes = useStyles();
  const dispatch = useDispatch()

  return (
    <>
      <Button className={classes.submit} onClick={(event:any)=>{handleClick(props.performance, dispatch)}} disabled={!props.performance.VideoClipId}>Performance Details</Button>
    </>
  );
}

export default DownloadButton;
