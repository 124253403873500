import React, {Dispatch} from "react";
import { DEFAULT_MOMENT_FORMAT } from "../lib/constants";
import { Button, Grid, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import DownloadButton from '../components/DownloadButton'

import { Performance } from "../lib/datamodels";
import { setPage } from '../redux/actions/RouteActions'
import { setPerformance } from "../redux/actions/PerformancesActions";
import {StylesType} from "../App";

const PerformanceRow = (performance: Performance, buttonText: string, dispatch: Dispatch<any>, classes: StylesType, past: boolean = false) => {
  const newPage = past ? 'view' : 'upload'
  const {
    id,
    EventBrandName,
    EventName,
    ProgramName,
    DivisionName,
    TeamName,
    VideoSessionWindowEnd,
    RecordingSessionStartTime
  } = performance


  let submission
  if (past) {
    console.log('past')
    const recordingStart = moment(RecordingSessionStartTime).tz(moment.tz.guess()).format(DEFAULT_MOMENT_FORMAT)
    submission = `Video Received ${recordingStart}`
  } else {
    console.log('not past')
    const lastSubmission = moment(VideoSessionWindowEnd).tz(moment.tz.guess()).format(DEFAULT_MOMENT_FORMAT)
    submission = `Submissions End ${lastSubmission}`
  }
  return (
    <Grid item md={12} key={id} className={classes.performance}>
      <Grid container>
        <Grid item md={3}>
          <Typography>{EventBrandName}<br/>{EventName}</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography>{TeamName}<br/>{ProgramName}</Typography>
        </Grid>
        <Grid item md={5}>
          <Typography>{DivisionName}<br/>{submission}</Typography>
        </Grid>
        <Grid>
          { past && <DownloadButton performance={performance} />}
          { !past && <Button className={classes.submit} onClick={()=>{dispatch(setPerformance(performance)); dispatch(setPage(newPage))}}>{buttonText}</Button> }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PerformanceRow
