import React from 'react';
import {Button, Card, CardHeader, Grid, Paper, Typography} from "@material-ui/core";
import {useSelector} from 'react-redux';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Performance} from '../lib/datamodels';
import moment from 'moment';
import {useStyles} from "../App";

const PerformanceDetails = () => {
  const classes = useStyles();

  const performance: Performance = useSelector((state: any) => state.performances.performance)
  const downloadVideo: string = useSelector((state: any) => state.performances.downloadVideo)

  console.log(performance)

  return (
    <Card className="Card">
      <CardHeader title="Performance Details" className="Card-Header"/>
      <Paper>
        <Grid container justify="center" spacing={5}>
          <Grid item md={12}>
            <Typography variant="h6">Event: {performance.EventName} - {performance.EventBrandName} - {performance.EventCity}</Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Video Submitted: {moment(performance.updatedAt).format('LLLL')}</Typography>
          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={2}>
            <Typography variant="h6">Team: {performance.TeamName}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="h6">Program: {performance.ProgramName}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="h6">Division: {performance.DivisionName}</Typography>
          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={12}>
            <a href={downloadVideo} download><Button className={classes.downloadButton}>Download Video</Button></a>
          </Grid>
        </Grid>
      </Paper>
    </Card>
  )
}

export default PerformanceDetails
