import moment from 'moment';

// AsyncStorage keys
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const VIDEO_MAP = 'VIDEO_MAP';

export const MIN_RECORDING_WINDOW_HOURS = 24 * 3

export const BASE_URL = 'https://aslinkapi.cheerreplay.com';

export const DEFAULT_MOMENT_FORMAT = "MMM D, YY @ h:mma";

export enum DATE_ADJUSTMENT_KEYS {
    THIRTY_DAYS = 'THIRTY_DAYS',
    NINETY_DAYS = 'NINETY_DAYS',
    ONE_HUNDRED_EIGHTY_DAYS = 'ONE_HUNDRED_EIGHTY_DAYS',
    ONE_YEAR = 'ONE_YEAR'
}

export interface DateAdjustment {
    string: string;
    key: string;
    StartDate: any;
    EndDate: any;
}

export const DATE_ADJUSTMENTS: {[key: string]: DateAdjustment} = {
    THIRTY_DAYS: {
        string: '30 Days',
        key: DATE_ADJUSTMENT_KEYS.THIRTY_DAYS,
        StartDate: () => moment().subtract(30, 'days').unix(),
        EndDate: () => moment().unix(),
    },
    NINETY_DAYS: {
        string: '90 Days',
        key: DATE_ADJUSTMENT_KEYS.NINETY_DAYS,
        StartDate: () => moment().subtract(90, 'days').unix(),
        EndDate: () => moment().unix(),
    },
    ONE_HUNDRED_EIGHTY_DAYS: {
        string: '180 Days',
        key: DATE_ADJUSTMENT_KEYS.ONE_HUNDRED_EIGHTY_DAYS,
        StartDate: () => moment().subtract(180, 'days').unix(),
        EndDate: () => moment().unix(),
    },
    ONE_YEAR: {
        string: '365 Days',
        key: DATE_ADJUSTMENT_KEYS.ONE_YEAR,
        StartDate: () => moment().subtract(1, 'year').unix(),
        EndDate: () => moment().unix(),
    }
};
