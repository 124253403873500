import {ACCESS_TOKEN, BASE_URL, REFRESH_TOKEN} from '../../lib/constants';
import {createAction} from 'redux-actions';
import {SET_LOGGED_IN} from '../actionTypes';

interface LoginUserOptions {
  email: string;
  password: string;
}

export const setLoggedIn = createAction<boolean>(SET_LOGGED_IN);

function loginUser(info: LoginUserOptions) {
  return async (dispatch: any) => {
    const response = await fetch(
      `${BASE_URL}/auth/login`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(info),
      }
    );
    if (response.ok) {
      const respJson = await response.json();
      console.log('setting', respJson.IdToken)
      localStorage.setItem(ACCESS_TOKEN, respJson.IdToken)
      localStorage.setItem(REFRESH_TOKEN, respJson.RefreshToken)
      const access = localStorage.getItem(ACCESS_TOKEN)
      console.log('set', access)

      dispatch(setLoggedIn(true));
    } else {
      throw new Error('Login Failed, please try again');
    }
  }
}

function checkIfLoggedIn() {
  return async (dispatch: any) => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN)
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    if (refreshToken) {
      dispatch(setLoggedIn(true));
      return true;
    }
    return false;
  }
}

function signOutUser() {
  return async (dispatch: any) => {
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    dispatch(setLoggedIn(false));
  }
}

export {
  loginUser,
  signOutUser,
};

export type {
  LoginUserOptions
}
