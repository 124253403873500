import React from 'react';
import './App.css';
import {Provider} from 'react-redux';
import store from './redux/store'
import {createMuiTheme, makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {CssBaseline, Link, Typography} from "@material-ui/core";
import {BrowserRouter as Router} from "react-router-dom";
import MainPage from "./components/MainPage";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    top: '0px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.text.secondary,
    borderTopWidth: '0px'
  },
  menu: {
    flexGrow: 1,
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  selectedButton: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase',
    fontWeight: 'bold',
    backgroundColor: "#d32828",
    color: "white",
    '&:hover': {
      backgroundColor: "#940f0f",
    }
  },
  home: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    lineHeight: theme.typography.h1.lineHeight,
  },
  username: {
    paddingTop: theme.spacing(4),
    textAlign: 'end',
    paddingRight: theme.spacing(2),
    fontSize: '.75rem',
  },
  headerImage: {
    height: '75px',
    marginLeft: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#b30808',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#940f0f",
    }
  },
  performance: {
    borderBottom: '1px solid black'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  downloadButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#b30808',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#940f0f",
    }
  },
  progress: {
    color: '#b30808'
  }

}));

export type StylesType = ReturnType<typeof useStyles>

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#FFFFFF"
    }
  }
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://cheerreplay.com/">
        Cheer Replay, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function App() {
  return (
    <Router>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline/>
          <MainPage/>
        </MuiThemeProvider>
      </Provider>
    </Router>
  );
}

export default App;
