import React, { useState } from 'react';
import {Button, Card, CardHeader, Grid, Paper, Typography} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { signOutUser } from '../redux/actions/UserActions'
import {makeStyles} from "@material-ui/core/styles";
import {ACCESS_TOKEN} from "../lib/constants";
import {useStyles} from "../App";

const Account = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
  const classes = useStyles();

  const decodedJwt: any = jwtDecode(accessToken);
  const {
    family_name,
    given_name,
    phone_number,
    email,
  } = decodedJwt;
  const add1 = decodedJwt['custom:location_add1'];
  const city = decodedJwt['custom:location_city'];
  const state = decodedJwt['custom:location_stateProv'];
  const postalCode = decodedJwt['custom:location_postalCode'];
  const country = decodedJwt['custom:location_country'];

  const dispatch = useDispatch()
  return (
    <Card className="Card">
      <CardHeader title="Account Information" className="Card-Header"/>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{given_name} {family_name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{add1}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{city}, {state} {postalCode} - {country}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{phone_number}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={e => dispatch(signOutUser())}>
              Log Out
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Card>
  )
  //return (<></>)
}

export default Account

