import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { loginUser } from '../redux/actions/UserActions'
import {TransitionProps} from "@material-ui/core/transitions";
import {useStyles} from "../App";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useDispatch()

  const submitForm = async (event: any) => {
    event.preventDefault();
    const doLogin = loginUser({ email,  password })
    try {
      await doLogin(dispatch)
    } catch (e) {
      setDialogOpen(true)
      console.log(e)
    }
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const dialog = (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Invalid Email or Password"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          The email and password you entered is an invalid combination, please try again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitForm}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.button}
              component="a"
              href="https://app.cheerreplay.com/signup"
            >
              Register
            </Button>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              style={{marginTop:15}}
              component="a"
              href="https://app.cheerreplay.com/forgot_password"
            >
              Forgot Password?
            </Button>
        </form>
      </div>
      { dialog }
    </Container>
  );
};

export default Login;
