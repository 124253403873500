import React, { useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getPerformances} from '../redux/actions/PerformancesActions'
import { Card, CardHeader, Grid, Paper, Typography } from "@material-ui/core";
import {Performance} from "../lib/datamodels"
import moment from "moment-timezone"
import { MIN_RECORDING_WINDOW_HOURS } from "../lib/constants";
import PerformanceRow from "../components/PerformanceRow";
import HeaderRow from "../components/HeaderRow";
import helpers from "../lib/helpers";
import {useStyles} from "../App";
import LinearProgress from '@material-ui/core/LinearProgress';

const Current = () => {
  console.log('top of current')
  const dispatch = useDispatch()
  const styles = useStyles()
  const performances = useSelector((state: any) => state.performances.performances)
  const performancesLoading = useSelector((state: any) => state.performances.loading)

  useEffect(()=> {
    dispatch(getPerformances())
  }, [])

  const currentPerformances = useMemo(()=> {
    if (performances && performances.length) {
      return performances.filter((performance: Performance) => {
        const {
          RecordingSessionStarted,
          VideoSessionWindowEnd,
          RecordingPermitted,
          VideoSessionWindowStart,
        } = performance;
  
        const sessionStartTime = helpers.getTzInMs(VideoSessionWindowStart);
        const sessionEndTime = helpers.getTzInMs(VideoSessionWindowEnd);
        const now = moment().unix();
        const recordingWindow = moment.duration(moment(VideoSessionWindowEnd).diff(moment(VideoSessionWindowStart)))
        const hours = recordingWindow.asHours();
        return RecordingPermitted && (now < sessionEndTime) && (now > sessionStartTime) && performance.SubmittedVideoClip !== 1 && hours > MIN_RECORDING_WINDOW_HOURS
      })
    } else {
      return []
    }
  }, [performances])


  return performances && performances.length ? (
    <Card className="Card">
      <CardHeader title="Current Performances" className="Card-Header"/>
      <Paper>
        <Grid container>
          
          <HeaderRow styles />
          {currentPerformances.map((performance: Performance) => {
            return PerformanceRow(performance, 'Submit Video', dispatch, styles)
          })}
        </Grid>
        {performancesLoading ? 
        <>
          <LinearProgress/>
          <Typography>Finding Performances</Typography>
        </> : <>
          {currentPerformances.length === 0 ?
          <Typography>No Performances Found</Typography>
          : <Typography>Displaying {currentPerformances.length} performance(s)</Typography>
          }
        </>}
      </Paper>
    </Card>
  ) : <></>
}

export default Current

