import {
  SET_PERFORMANCES,
  SET_PERFORMANCE,
  SET_CURRENT_RECORDING,
  SET_DATE_ADJUSTMENT,
  SET_DOWNLOAD_VIDEO,
  SET_PERFORMANCES_LOADING
} from "../actionTypes";
import { Performance } from "../../lib/datamodels";
import { DATE_ADJUSTMENT_KEYS } from '../../lib/constants';

interface PerformancesInterface {
    performances: Performance[];
    performance: Performance | null;
    currentRecording: number | null;
    dateAdjustment: DATE_ADJUSTMENT_KEYS;
    downloadVideo: string | null;
    downloadClipId: string | null;
    loading: boolean;
}

const initialState: PerformancesInterface = {
    performances: [],
    performance: null,
    currentRecording: null,
    dateAdjustment: DATE_ADJUSTMENT_KEYS.THIRTY_DAYS,
    downloadVideo: null,
    downloadClipId: null,
    loading: true
};

interface UserAction {
  type: string;
  payload: any;
}

export default function (state = initialState, action: UserAction) {
    switch (action.type) {
        case SET_PERFORMANCES: {
            return {
                ...state,
                performances: action.payload,
                loading: false
            };
        }
        case SET_PERFORMANCE: {
            return {
                ...state,
                performance: action.payload,
            }
        }
        case SET_CURRENT_RECORDING: {
            return {
                ...state,
                currentRecording: action.payload[0],
            }
        }
        case SET_DATE_ADJUSTMENT: {
            return {
                ...state,
                dateAdjustment: action.payload,
            }
        }
      case SET_DOWNLOAD_VIDEO: {
        return {
          ...state,
          downloadVideo: action.payload[0],
          downloadClipId: action.payload[1]
        }
      }
      case SET_PERFORMANCES_LOADING: {
          return {
              ...state,
              loading: true
          }
      }
        default:
            return state;
    }
}
