import {ACCESS_TOKEN, BASE_URL, REFRESH_TOKEN} from '../lib/constants';

class HttpClient {
  checkAccessToken = async () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (!accessToken) {
      return false
    }
    return accessToken;
  }

  useRefreshToken = async () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (!refreshToken) {
      // TODO: Kick user to login because this shouldn't happen since refresh tokens are infinite.
    }
    const response = await fetch(
      `${BASE_URL}/auth/refresh`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          RefreshToken: refreshToken,
        }),
        method: 'POST',
      }
    )
    if (response.ok) {
      const respJson = await response.json();
      localStorage.setItem(ACCESS_TOKEN, respJson.IdToken);
      return respJson.IdToken;
    } else {
      // TODO: Kick user to login because this shouldn't happen since refresh tokens are infinite.
    }
  }

  get = async (path) => {
    let token = await this.checkAccessToken();
    if (token) {
      try {
        let response = await fetch(
          `${BASE_URL}${path}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.status === 401) {
          token = await this.useRefreshToken();
          response = await fetch(
            `${BASE_URL}${path}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );
        }
        if (response.ok) {
          const respJson = await response.json();
          return respJson;
        }
      } catch (error) {
        // do nothing with error for now
      }
    }
  }

  rawPost = async (path, body) => {
    try {
      let response = await fetch(
        path,
        {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow', // manual, *follow, error
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        const respJson = await response.json();
        return respJson;
      }
    } catch (error) {
      // Do nothing with error for now
      console.log(error)
    }
  }

  post = async (path, body) => {
    let token = await this.checkAccessToken();
    if (token) {
      try {
        let response = await fetch(
          `${BASE_URL}${path}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        );
        if (response.status === 401) {
          token = await this.useRefreshToken();
          response = await fetch(
            `${BASE_URL}${path}`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            }
          );
        }
        if (response.ok) {
          const respJson = await response.json();
          return respJson;
        }
      } catch (error) {
        // Do nothing with error for now
      }
    }
  }
}

const httpClient = new HttpClient();

export default httpClient;
