import { SET_PAGE } from "../actionTypes";

const initialState = {
  page: 'current',
};

interface RouteAction {
    type: string;
    payload: any;
}

export default function(state = initialState, action: RouteAction) {
  switch (action.type) {
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      }
    }
    default:
      return state;
  }
}
