import {createAction} from 'redux-actions';
import {
  SET_CURRENT_RECORDING,
  SET_DATE_ADJUSTMENT,
  SET_DOWNLOAD_VIDEO,
  SET_PERFORMANCE,
  SET_PERFORMANCES,
  SET_PERFORMANCES_LOADING
} from '../actionTypes';
import client from '../../services/httpClient';
import {Performance} from '../../lib/datamodels';
// import * as FileSystem from 'expo-file-system';
import {DATE_ADJUSTMENT_KEYS, DATE_ADJUSTMENTS} from '../../lib/constants';

const setPerformances = createAction<Performance[]>(SET_PERFORMANCES)
const setPerformancesLoading = createAction(SET_PERFORMANCES_LOADING)

function getPerformances() {
  return async (dispatch: any, getState: any) => {
    dispatch(setPerformancesLoading())
    const {dateAdjustment} = getState().performances;
    const adjustment = DATE_ADJUSTMENTS[dateAdjustment];
    const url = `/performances/?StartDate=${adjustment.StartDate()}&EndDate=${adjustment.EndDate()}`;
    let performances = await client.get(url);
    if (typeof performances?.then === 'function') {
      performances = await performances
    }
    dispatch(setPerformances(performances));
  };
}

const setPerformance = createAction<Performance>(SET_PERFORMANCE);

function getPerformanceDetails(performanceId: number) {
  return async (dispatch: any) => {
    const url = `/performances/${performanceId}`;
    const response = await client.get(url);
    dispatch(setPerformance(response));
  }
}

const setCurrentRecording = createAction<number[]>(SET_CURRENT_RECORDING);

function beginVideoRecording(performanceId: number) {
  return async () => {
    const url = `/video_clips`;
    const response = await client.post(url, {
      performance_id: performanceId,
    });
    return response[0]
  }
}

const setDownloadVideo = createAction<string[]>(SET_DOWNLOAD_VIDEO);

function getVideoClip(clipId: number | null) {
  return async (dispatch: any) => {
    if (!clipId) {
      return dispatch(setDownloadVideo(['', '']));
    } else {
      const url = `/video_clips/${clipId}`;
      return client.get(url).then((result: any) => {
        dispatch(setDownloadVideo([result.url, clipId]));
      })
    }
  }
}

const setDateAdjustment = createAction<DATE_ADJUSTMENT_KEYS>(SET_DATE_ADJUSTMENT);

function submitVideo(videoId: number, performanceId: number, extension: string, mimeType: string) {
  return async () => {
    const url = '/upload_video_clip';
    return await client.post(url, {
      Mimetype: mimeType,
      Extension: `.${extension}`,
      id: videoId,
      Duration: 90,
    })
  }
}

async function verifyVideo(performanceId: number, videoId: number) {
  const verifyUrl = `/video_clips/confirm_upload`;
  const confirmResults = await client.post(verifyUrl, {
    id: videoId,
  });
  const confirmSubmitUrl = `/video_clips/submit`;
  await client.post(confirmSubmitUrl, {
    performance_id: performanceId,
    video_clip_id: videoId,
  });
  return confirmResults
}

function getVideoMetaData(s3Key: string) {
  return async () => {
    const body = {
      Key: s3Key,
    }
    return await client.rawPost('http://h3z40pl5fb.execute-api.us-west-2.amazonaws.com/prod/media-info', body);
  }
}

export {
  getPerformanceDetails,
  getPerformances,
  setPerformance,
  beginVideoRecording,
  setCurrentRecording,
  setDateAdjustment,
  setPerformances,
  submitVideo,
  verifyVideo,
  getVideoMetaData,
  getVideoClip,
  setDownloadVideo
};
